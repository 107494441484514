<template >
  <div class="con" v-if="!$store.state.pcshow">
    <img src="../../assets/img/index/companyIntro12.png" alt="" />
    <div class="yj">
      <h4><span></span>孵化器平台</h4>
      <p>目前亿联体集团拥有孵化器产业园区16个，立足大健康相关领域全产业链资源对园区内在孵企业进行创业帮扶，吸引了大量的医疗健康企业。</p>
      <p>北京亿联祥和独角兽企业管理有限公司成立于2019年，由亿联体集团联合北京祥辉投资管理有限公司与中关村跨界创新联盟共同发起设立 的独角兽孵化平台企业。该平台结合自身科技优势与亿联体集团资源优势以国家制度意志为体现，建立亿元级别投资储备资金池，运用完 善的系统孵化流程，在一年内成功为24家企业进行帮扶并入驻平台基地，并先后为园区孵化企业进行项目投融资达4000万元。</p>
    </div>
  </div>
  <div class="main" v-else>
    
  </div>
</template>

<script>
export default {};
</script>

<style scoped lang="stylus" >
  @media screen and (max-width: 960px) {
    .con{
      width: 100%;
      background: #F5F5F5;
      
      >img{
        display: block
        width:7.5rem;
        height:3rem;
      }
      .yj{
        display: block;
        width: calc( 100% - 0.35rem );
        background: #ffffff;
        padding-left:0.35rem;
        padding-bottom:0.38rem;
        border-top: 0.3rem solid #e5e5e5;
        border-bottom: 0.4rem solid #e5e5e5;

        >h4{
          font-size: 0.26rem;
          font-family: Source Han Sans CN;
          font-weight: 500;
          color: #333333;
          line-height: 0.39rem;
          padding-top:0.35rem;
          padding-bottom:0.1rem;

          span{
            display: inline-block;
            width: 0.03rem;
            height: 0.23rem;
            background: #0085D0;
            margin-right:0.07rem;
          }

        }

        p{
            width: 6.8rem;
            font-size: 0.24rem;
            font-family: Source Han Sans CN;
            font-weight: 400;
            color: #333333;
            line-height: 0.4rem;
        }
        :nth-child(2){
            margin-bottom: 0.2rem;
        }
      }
      
    }
  }
  @media screen and (min-width: 960px) { 
    
  }
</style>